<template>
  <b-modal
    :id="id"
    size="xl"
    title="Danh sách lao động được giới thiệu"
    centered
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    hide-footer
  >
    <div class="header-action">
      <search @change="search" />
      <button-component
        class="ml-2"
        content-btn="Tuyển dụng"
        variant="primary"
        icon-btn="CheckSquareIcon"
        @click="recruitWorkerItems"
      />
    </div>
    <vue-good-table
      ref="user-table"
      class="mh-60"
      style-class="vgt-table"
      :columns="columns"
      :rows="dataList || []"
      :select-options="{
        enabled: true,
        vertialAlignTop: true,
        selectOnCheckboxOnly: true,
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true,
        selectAllByGroup: true,
      }"
      @on-selected-rows-change="selectRowTable"
    >
      <template
        slot="table-column"
        slot-scope="props"
      >
        <span class="text-nowrap">
          {{ props.column.label }}
        </span>
      </template>

      <template
        slot="table-row"
        slot-scope="props"
      >
        <span v-if="props.column.field === 'action'">
          <span
            class="ml-2"
            @click="recruitWorker(props.row.id)"
          >
            <feather-icon
              v-b-tooltip.hover.top="'Tuyển dụng'"
              icon="CheckSquareIcon"
              size="18"
              class="text-body"
            />
          </span>
        </span>
        <span v-else-if="props.column.field === 'birthDay'">
          {{ props.row.birthDay | formatDate }}
        </span>
        <span v-else-if="props.column.field === 'gender'">
          {{ props.row.gender ? 'nam': 'nữ' }}
        </span>
      </template>
    </vue-good-table>
    <my-pagination
      :totalItems="totalRecord"
      @pageClick="handlePageClick"
    />
  </b-modal>
</template>

<script>
import {
  BMedia,
  BDropdown,
  BDropdownItem,
  BRow,
  BCol,
  BFormGroup,
  BBadge,
  BModal,
  BButton,
  VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ButtonComponent from '@/components/button/ButtonComponent.vue'
import Search from '@/components/search/Search.vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import store from '../../store'

export default {
  components: {
    VueGoodTable,
    MyPagination,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BFormGroup,
    BMedia,
    Search,
    BBadge,
    BModal,
    BButton,
    vSelect,
    ButtonComponent,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    idDetailBusiness: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      columns: [
        {
          label: 'Họ và tên',
          field: 'name',
          sortable: false,
        },
        {
          label: 'Ngày sinh',
          field: 'birthDay',
          sortable: false,
        },
        {
          label: 'Giới tính',
          field: 'gender',
          sortable: false,
        },
        {
          label: 'Địa chỉ',
          field: 'address',
          sortable: false,
        },
        {
          label: 'Số điện thoại',
          field: 'phoneNumber',
          sortable: false,
        },
        {
          label: 'Trình độ học vấn',
          field: 'academicLevelString',
          sortable: false,
        },
        {
          label: 'Chức năng',
          field: 'action',
          width: '175px',
          sortable: false,
        },
      ],
      dataList: [],
      totalRecord: 0,
      queryParams: {
        bussinessRecruitmentId: '',
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
      recruit: {
        bussinesssRecruitmentId: '',
        ids: [],
      },
    }
  },
  watch: {
    idDetailBusiness(val) {
      if (val) {
        this.queryParams.bussinessRecruitmentId = val
        this.fecthListUser()
      }
    },
  },
  mounted() {
    if (this.idDetailBusiness) {
      this.queryParams.bussinessRecruitmentId = this.idDetailBusiness
      this.fecthListUser()
    }
  },
  methods: {
    async fecthListUser() {
      this.$showLoading()
      const { data } = await store.getListUser(this.queryParams)
      if (data) {
        this.dataList = data.pageLists
        this.totalRecord = data.totalRecord
      }
      this.$hideLoading()
    },
    handlePageClick(pageNumber, pageSize) {
      this.queryParams = {
        ...this.queryParams,
        pageNumber,
        pageSize,
      }
      this.fecthListUser()
    },
    search(val) {
      this.queryParams = {
        ...this.queryParams,
        pageNumber: 1,
        key: val,
      }
      this.fecthListUser()
    },

    async recruitWorker(id) {
      this.recruit = {
        bussinesssRecruitmentId: this.idDetailBusiness,
        ids: [id],
      }
      await store.recruitWorker(this.recruit).then(response => {
        if (response.success) {
          this.$root.$bvToast.toast('Tuyển dụng thành công', {
            title: 'Thông báo',
            variant: 'success',
            toaster: this.$toastPosition,
            solid: true,
          })
          this.fecthListUser()
        }
      }).catch(e => {
        this.$root.$bvToast.toast(e.response.data?.errors[0], {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    selectRowTable(val) {
      const { selectedRows } = val
      this.showBtnMultiDelete = selectedRows.length > 0
    },

    async recruitWorkerItems() {
      const array = this.$refs['user-table'].selectedRows.map(item => item.id)
      this.recruit = {
        bussinesssRecruitmentId: this.idDetailBusiness,
        ids: array,
      }
      await store.recruitWorker(this.recruit).then(response => {
        if (response.success) {
          this.$root.$bvToast.toast('Tuyển dụng thành công', {
            title: 'Thông báo',
            variant: 'success',
            toaster: this.$toastPosition,
            solid: true,
          })
          this.fecthListUser()
        }
      }).catch(e => {
        this.$root.$bvToast.toast(e.response.data?.errors[0], {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },
  },
}
</script>
