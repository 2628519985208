<template>
  <b-modal
    id="modal-add-demand"
    size="xl"
    centered
    title="Thêm nhu cầu tuyển dụng"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    @ok="handleOk"
    @hidden="resetData"
  >

    <validation-observer ref="rule">
      <b-row>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group>
            <label>Trung tâm dịch vụ việc làm <span class="text-danger">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="Trung tâm dịch vụ việc làm"
              rules="required"
              :custom-messages="customBusiness"
            >
              <v-select
                v-model="detailDemand.employmentServiceCenterId"
                :reduce="(name) => name.id"
                label="name"
                :class="{'is-invalid':errors.length > 0 }"
                :options="comboboxBusiness || []"
                placeholder="Trung tâm dịch vụ việc làm"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group>
            <label>Ngày gửi nhu cầu tuyển dụng</label>
            <date-picker
              v-model="detailDemand.dateRecruit"
              :disabledInput="true"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group>
            <label>Ngày hêt hạn tuyển dụng</label>
            <date-picker
              v-model="detailDemand.dateExpired"
              :disabledInput="true"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group>
            <label>Số lao đông trình độ đại học cần tuyển</label>
            <b-form-input
              v-model="detailDemand.amountUniversity"
              type="number"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group>
            <label>Số lao đông trình độ cao đẳng cần tuyển</label>
            <b-form-input
              v-model="detailDemand.amountCollege"
              type="number"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group>
            <label>Số lao đông trình độ trung cấp cần tuyển</label>
            <b-form-input
              v-model="detailDemand.amountIntermediate"
              type="number"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group>
            <label>Số lao đông trình độ sơ cấp cần tuyển</label>
            <b-form-input
              v-model="detailDemand.amountOverPrimary"
              type="number"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group>
            <label>Số lao đông trình độ chưa qua đào tạo cần tuyển</label>
            <b-form-input
              v-model="detailDemand.amountNoneTraining"
              type="number"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate/dist/vee-validate.full'
import {
  BRow,
  BCol,
  BFormGroup,
  BModal,
  BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import DatePicker from '@/components/datepicker/DatePicker.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BModal,
    vSelect,
    BFormInput,
    DatePicker,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    comboboxBusiness: {
      type: Array,
      required: true,
    },
    data: {
      type: Object,
      default() {
        return {
          dateRecruit: null,
          dateExpired: null,
          amountUniversity: null,
          amountCollege: null,
          amountIntermediate: null,
          amountOverPrimary: null,
          amountNoneTraining: null,
          employmentServiceCenterId: null,
        }
      },
    },
  },
  data() {
    return {
      detailDemand: JSON.parse(JSON.stringify(this.data)),
      customBusiness: {
        required: 'Trung tâm dịch vụ việc làm là bắt buộc',
      },
    }
  },
  watch: {
    data: {
      deep: true,
      handler(val) {
        this.detailDemand = JSON.parse(JSON.stringify(val))
      },
    },
  },
  methods: {
    resetData() {
      this.detailDemand = JSON.parse(JSON.stringify(this.data))
    },
    handleOk(e) {
      e.preventDefault()
      this.$refs.rule.validate().then(async success => {
        if (success) {
          this.detailDemand.amountUniversity = Number(this.detailDemand.amountUniversity)
          this.detailDemand.amountCollege = Number(this.detailDemand.amountCollege)
          this.detailDemand.amountIntermediate = Number(this.detailDemand.amountIntermediate)
          this.detailDemand.amountOverPrimary = Number(this.detailDemand.amountOverPrimary)
          this.detailDemand.amountNoneTraining = Number(this.detailDemand.amountNoneTraining)
          this.$bvModal.hide('modal-add-demand')
          this.$emit('update', this.detailDemand)
        }
      })
    },
  },
}
</script>
