var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"page-container mb-2"},[_c('h4',{staticClass:"mb-2"},[_vm._v(" Bộ lọc ")]),_c('b-row',[_c('b-col',{attrs:{"cols":"12","sm":"6","md":"2"}},[_c('b-form-group',[_c('label',[_vm._v("Từ ngày")]),_c('date-picker',{attrs:{"disabledInput":true},on:{"input":_vm.filterBusiness},model:{value:(_vm.queryParams.startTime),callback:function ($$v) {_vm.$set(_vm.queryParams, "startTime", $$v)},expression:"queryParams.startTime"}})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"6","md":"2"}},[_c('b-form-group',[_c('label',[_vm._v("Đến ngày")]),_c('date-picker',{attrs:{"disabledInput":true},on:{"input":_vm.filterBusiness},model:{value:(_vm.queryParams.endTime),callback:function ($$v) {_vm.$set(_vm.queryParams, "endTime", $$v)},expression:"queryParams.endTime"}})],1)],1)],1)],1),_c('div',{staticClass:"page-container-table",attrs:{"id":"users"}},[_c('button-all-header',{attrs:{"contentBtnAdd":'Thêm nhu cầu',"showBtnMultiDelete":_vm.showBtnMultiDelete,"hideImportFile":false,"hideExportFile":false,"hideDowload":false},on:{"clickDelete":_vm.deleteItems,"clickAdd":_vm.addBusiness,"search":function($event){return _vm.search($event)}}}),_c('vue-good-table',{ref:"demand-table",staticClass:"mh-60",attrs:{"style-class":"vgt-table","columns":_vm.columns,"rows":_vm.dataList || [],"select-options":{
        enabled: true,
        vertialAlignTop: true,
        selectOnCheckboxOnly: true,
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true,
        selectAllByGroup: true,
      }},on:{"on-selected-rows-change":_vm.selectRowTable},scopedSlots:_vm._u([{key:"table-column",fn:function(props){return [_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(props.column.label)+" ")])]}},{key:"table-row",fn:function(props){return [(props.column.field === 'action')?_c('span',[_c('span',{staticClass:"ml-2",on:{"click":function($event){return _vm.showModalListUser(props.row.id)}}},[_c('feather-icon',{staticClass:"text-body",attrs:{"icon":"EyeIcon","size":"18"}})],1),_c('span',{staticClass:"ml-2",on:{"click":function($event){return _vm.getDetailBusiness(props.row.id)}}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Chỉnh sửa'),expression:"'Chỉnh sửa'",modifiers:{"hover":true,"top":true}}],staticClass:"text-body",attrs:{"icon":"EditIcon","size":"18"}})],1),_c('span',{staticClass:"ml-2",on:{"click":function($event){return _vm.deleteItem(props.row.id)}}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Xóa'),expression:"'Xóa'",modifiers:{"hover":true,"top":true}}],staticClass:"text-body",attrs:{"icon":"Trash2Icon","size":"18"}})],1)]):(props.column.field === 'dateExpired')?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDate")(props.row.dateExpired))+" ")]):(props.column.field === 'dateRecruit')?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDate")(props.row.dateRecruit))+" ")]):_vm._e()]}}])}),_c('my-pagination',{attrs:{"totalItems":_vm.totalRecord},on:{"pageClick":_vm.handlePageClick}}),_c('confirm-modal',{attrs:{"id":"confirm-modal","title":'',"content":"Bạn có chắc chắn muốn xóa nhu cầu tuyển dụng của doanh nghiệp không ?"},on:{"accept":_vm.acceptDelete}})],1),_c('modal-add-demand',{attrs:{"comboboxBusiness":_vm.comboboxBusiness,"data":_vm.detailBusiness},on:{"update":_vm.updateBusiness}}),_c('modal-list-user',{attrs:{"id":_vm.idModalList,"idDetailBusiness":_vm.idDetailBusiness}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }