<template>
  <div>
    <div class="page-container mb-2">
      <h4 class="mb-2">
        Bộ lọc
      </h4>
      <b-row>
        <b-col
          cols="12"
          sm="6"
          md="2"
        >
          <b-form-group>
            <label>Từ ngày</label>
            <date-picker
              v-model="queryParams.startTime"
              :disabledInput="true"
              @input="filterBusiness"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          md="2"
        >
          <b-form-group>
            <label>Đến ngày</label>
            <date-picker
              v-model="queryParams.endTime"
              :disabledInput="true"
              @input="filterBusiness"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <div
      id="users"
      class="page-container-table"
    >
      <button-all-header
        :contentBtnAdd="'Thêm nhu cầu'"
        :showBtnMultiDelete="showBtnMultiDelete"
        :hideImportFile="false"
        :hideExportFile="false"
        :hideDowload="false"
        @clickDelete="deleteItems"
        @clickAdd="addBusiness"
        @search="search($event)"
      />
      <vue-good-table
        ref="demand-table"
        class="mh-60"
        style-class="vgt-table"
        :columns="columns"
        :rows="dataList || []"
        :select-options="{
          enabled: true,
          vertialAlignTop: true,
          selectOnCheckboxOnly: true,
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true,
          selectAllByGroup: true,
        }"
        @on-selected-rows-change="selectRowTable"
      >

        <template
          slot="table-column"
          slot-scope="props"
        >
          <span class="text-nowrap">
            {{ props.column.label }}
          </span>
        </template>

        <template
          slot="table-row"
          slot-scope="props"
        >
          <span v-if="props.column.field === 'action'">
            <span
              class="ml-2"
              @click="showModalListUser(props.row.id)"
            >
              <feather-icon
                icon="EyeIcon"
                size="18"
                class="text-body"
              />
            </span>
            <span
              class="ml-2"
              @click="getDetailBusiness(props.row.id)"
            >
              <feather-icon
                v-b-tooltip.hover.top="'Chỉnh sửa'"
                icon="EditIcon"
                size="18"
                class="text-body"
              />
            </span>
            <span
              class="ml-2"
              @click="deleteItem(props.row.id)"
            >
              <feather-icon
                v-b-tooltip.hover.top="'Xóa'"
                icon="Trash2Icon"
                size="18"
                class="text-body"
              />
            </span>
          </span>
          <span v-else-if="props.column.field === 'dateExpired'">
            {{ props.row.dateExpired | formatDate }}
          </span>
          <span v-else-if="props.column.field === 'dateRecruit'">
            {{ props.row.dateRecruit | formatDate }}
          </span>
        </template>
      </vue-good-table>

      <my-pagination
        :totalItems="totalRecord"
        @pageClick="handlePageClick"
      />

      <confirm-modal
        id="confirm-modal"
        :title="''"
        content="Bạn có chắc chắn muốn xóa nhu cầu tuyển dụng của doanh nghiệp không ?"
        @accept="acceptDelete"
      />
    </div>

    <!-- <modal-list-user /> -->

    <modal-add-demand
      :comboboxBusiness="comboboxBusiness"
      :data="detailBusiness"
      @update="updateBusiness"
    />

    <modal-list-user
      :id="idModalList"
      :idDetailBusiness="idDetailBusiness"
    />
  </div>
</template>

<script>
import {
  VBTooltip,
  BFormGroup,
  BRow,
  BCol,
} from 'bootstrap-vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import Search from '@/components/search/Search.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import DatePicker from '@/components/datepicker/DatePicker.vue'
import ModalListUser from './components/ModalListUser.vue'
import ModalAddDemand from './components/ModalAddDemand.vue'
import store from '../store'

export default {
  components: {
    VueGoodTable,
    MyPagination,
    Search,
    ConfirmModal,
    VBTooltip,
    ButtonAllHeader,
    BFormGroup,
    BRow,
    BCol,
    DatePicker,
    ModalListUser,
    ModalAddDemand,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },

  data() {
    return {
      queryParams: {
        startTime: null,
        endTime: null,
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
      columns: [
        {
          label: 'Trung tâm dịch vụ việc làm',
          field: 'employmentServiceCenter',
          sortable: false,
        },
        {
          label: 'Ngày người nhu cầu tuyển dụng',
          field: 'dateRecruit',
          sortable: false,
        },
        {
          label: 'Ngày hết hạn',
          field: 'dateExpired',
          sortable: false,
        },
        {
          label: 'Chức năng',
          field: 'action',
          sortable: false,
          width: '175px',
        },
      ],
      dataList: [],
      totalRecord: 0,
      showBtnMultiDelete: false,
      deletedIds: [],
      comboboxBusiness: [],
      detailBusiness: undefined,
      idModalList: 'modal-list-user',
      idDetailBusiness: '',
    }
  },
  created() {
    this.getListBusiness()
    this.getComboboxBusiness()
  },
  methods: {

    // Hiển thị modal list người lao động được giới thiệu
    showModalListUser(val) {
      this.idDetailBusiness = val
      this.$bvModal.show(this.idModalList)
    },

    async getListBusiness() {
      this.$showLoading()
      const { data } = await store.getListBusiness(this.queryParams)
      if (data) {
        this.dataList = data.pageLists
        this.totalRecord = data.totalRecord
      }
      this.$hideLoading()
    },
    handlePageClick(pageNumber, pageSize) {
      this.queryParams = {
        ...this.queryParams,
        pageNumber,
        pageSize,
      }
      this.getListBusiness()
    },
    search(val) {
      this.queryParams = {
        ...this.queryParams,
        pageNumber: 1,
        key: val,
      }
      this.getListBusiness()
    },
    deleteItem(id) {
      this.deletedIds = [id]
      this.$bvModal.show('confirm-modal')
    },
    deleteItems() {
      this.deletedIds = this.$refs['demand-table'].selectedRows.map(item => item.id)
      this.$bvModal.show('confirm-modal')
    },
    selectRowTable(val) {
      const { selectedRows } = val
      this.showBtnMultiDelete = selectedRows.length > 0
    },
    async acceptDelete() {
      const res = await store.deleteDemand(this.deletedIds)
      if (res.success) {
        this.$root.$bvToast.toast('Xóa nhu cầu tuyển dụng của doanh nghiệp thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.getListBusiness()
      }
    },
    async getComboboxBusiness() {
      const { data } = await store.getComboboxBusiness()
      this.comboboxBusiness = data
    },
    async updateBusiness(info) {
      let variant = ''
      let message = ''
      if (!info.id) {
        const res = await store.addBusiness(info)
        if (res.success) {
          variant = 'success'
          message = 'Thêm nhu cầu tuyển dụng thành công'
        } else {
          variant = 'danger'
          message = 'Thêm nhu cầu tuyển dụng thất bại'
        }
      } else {
        const res = await store.editBusiness(info)
        if (res.success) {
          variant = 'success'
          message = 'Chỉnh sửa nhu cầu tuyển dụng thành công'
        } else {
          variant = 'danger'
          message = 'Chỉnh sửa nhu cầu tuyển dụng thất bại'
        }
      }
      this.getListBusiness()
      this.$root.$bvToast.toast(message, {
        title: 'Thông báo',
        variant,
        toaster: this.$toastPosition,
        solid: true,
      })
    },
    addBusiness() {
      this.detailBusiness = undefined
      this.$bvModal.show('modal-add-demand')
    },
    async getDetailBusiness(id) {
      const res = await store.getDetailBusiness(id)
      this.detailBusiness = res
      this.$bvModal.show('modal-add-demand')
    },
    async filterBusiness() {
      if (this.queryParams.startTime && this.queryParams.endTime) {
        const startTime = new Date(this.queryParams.startTime)
        const endTime = new Date(this.queryParams.endTime)
        if (startTime - endTime > 0) {
          this.$root.$bvToast.toast('Thời gian bắt đầu phải lớn hơn thời gian kết thúc', {
            title: 'Thông báo',
            variant: 'warning',
            toaster: this.$toastPosition,
            solid: true,
          })
          return
        }
      }
      this.getListBusiness()
    },
  },
}
</script>
