import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'

export default {
  async getListBusiness(payload) {
    try {
      const { data } = await axiosApiInstance.get('/BussinessRecruitment/bussinessRecruitments', { params: payload })
      return data
    } catch (err) {
      return err
    }
  },
  async getComboboxBusiness() {
    try {
      const { data } = await axiosApiInstance.get('/Bussiness/get-combobox-bussiness')
      return data
    } catch (err) {
      return err
    }
  },
  async addBusiness(payload) {
    try {
      const { data } = await axiosApiInstance.post('/BussinessRecruitment/bussinessRecruitments', payload)
      return data
    } catch (err) {
      return err
    }
  },
  async getDetailBusiness(payload) {
    try {
      const { data } = await axiosApiInstance.get(`/BussinessRecruitment/bussinessRecruitments/${payload}`)
      return data
    } catch (err) {
      return err
    }
  },
  async editBusiness(payload) {
    try {
      const { data } = await axiosApiInstance.put('/BussinessRecruitment/bussinessRecruitments', payload)
      return data
    } catch (err) {
      return err
    }
  },
  async deleteDemand(payload) {
    try {
      const { data } = await axiosApiInstance.post('/BussinessRecruitment/delete-bussiness-recruitments', payload)
      return data
    } catch (err) {
      return err
    }
  },

  async getListUser(payload) {
    try {
      const { data } = await axiosApiInstance.get('/IntroduceJob/list-bussiness-recruitment-worker', { params: payload })
      return data
    } catch (err) {
      return err
    }
  },

  async recruitWorker(payload) {
    try {
      const { data } = await axiosApiInstance.post('/BussinessRecruitment/recruit-workers', payload)
      return data
    } catch (err) {
      return err
    }
  },
}
