var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":_vm.id,"size":"xl","title":"Danh sách lao động được giới thiệu","centered":"","cancel-title":"Hủy bỏ","cancel-variant":"outline-primary","ok-title":"Lưu","hide-footer":""}},[_c('div',{staticClass:"header-action"},[_c('search',{on:{"change":_vm.search}}),_c('button-component',{staticClass:"ml-2",attrs:{"content-btn":"Tuyển dụng","variant":"primary","icon-btn":"CheckSquareIcon"},on:{"click":_vm.recruitWorkerItems}})],1),_c('vue-good-table',{ref:"user-table",staticClass:"mh-60",attrs:{"style-class":"vgt-table","columns":_vm.columns,"rows":_vm.dataList || [],"select-options":{
      enabled: true,
      vertialAlignTop: true,
      selectOnCheckboxOnly: true,
      selectionInfoClass: 'custom-class',
      selectionText: 'rows selected',
      clearSelectionText: 'clear',
      disableSelectInfo: true,
      selectAllByGroup: true,
    }},on:{"on-selected-rows-change":_vm.selectRowTable},scopedSlots:_vm._u([{key:"table-column",fn:function(props){return [_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(props.column.label)+" ")])]}},{key:"table-row",fn:function(props){return [(props.column.field === 'action')?_c('span',[_c('span',{staticClass:"ml-2",on:{"click":function($event){return _vm.recruitWorker(props.row.id)}}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Tuyển dụng'),expression:"'Tuyển dụng'",modifiers:{"hover":true,"top":true}}],staticClass:"text-body",attrs:{"icon":"CheckSquareIcon","size":"18"}})],1)]):(props.column.field === 'birthDay')?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDate")(props.row.birthDay))+" ")]):(props.column.field === 'gender')?_c('span',[_vm._v(" "+_vm._s(props.row.gender ? 'nam': 'nữ')+" ")]):_vm._e()]}}])}),_c('my-pagination',{attrs:{"totalItems":_vm.totalRecord},on:{"pageClick":_vm.handlePageClick}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }